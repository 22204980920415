import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import ModAlertBanner from "../../../components/Chronology/Modules/ModAlertBanner";
import ModDesescalada from "../../../components/Chronology/Modules/ModDesescalada";
import ModGraphFull from "../../../components/Chronology/Modules/ModGraphFull";

import Day92 from "./dia-13-jun";
import Day93 from "./dia-14-jun";
import Day94 from "./dia-15-jun";
import Day95 from "./dia-16-jun";
import Day96 from "./dia-17-jun";
import Day97 from "./dia-18-jun";
import Day98 from "./dia-19-jun";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <ModAlertBanner color="brand02">
        Se registran 150.000 nuevos casos de COVID-19 en un solo día casos a
        nivel mundial
      </ModAlertBanner>
      <ModDesescalada />
      <Day98 {...props} />
      <Day97 {...props} />
      <Day96 {...props} />
      <ModGraphFull
        name="mapa-fases-transicion-1706"
        alt="mapa transicion fases españa"
      />
      <Day95 {...props} />
      <Day94 {...props} />
      <ModGraphFull
        name="mapa-fases-transicion-1506"
        alt="mapa transicion fases españa"
      />
      <Day93 {...props} />
      <Day92 {...props} />
    </LayoutWeek>
  );
};

export default Week;
