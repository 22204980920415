import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 96",
  week: "Semana 14",
  day: "17",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-17",
  path: "/cronologia/semana-14#dia-17-jun/",
};

const Day96 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.578 casos mediante pruebas PCR. En ese mismo
          periodo, 97 personas han requerido hospitalización (6,1% de los
          diagnósticos), de los que 10 han sido ingresos en la UCI, y se han
          producido 30 fallecimientos.
        </ModText>
        <ModText>
          A partir de mañana, Cataluña entrará en la Fase 3 como una única
          unidad territorial, y habrá capacidad de movimiento por toda la
          comunidad autónoma. De esta forma, el 78% de la población española
          (casi 37 millones de personas) estará ya la última fase del Plan de
          Desescalada.
        </ModText>
        <ModText>
          Por otra parte, aquellos territorios que se vayan incorporando a la
          Nueva Normalidad, deberán seguir haciendo{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/covid-19/Paginas/uso-mascarilla-nueva-normalidad.aspx">
            uso obligatorio de la mascarilla
          </InlineLink>
          , en las siguientes situaciones:
        </ModText>
        <ModDotList>Siempre en el transporte público</ModDotList>
        <ModDotList>
          Cuando no se pueda mantener la distancia de seguridad en la vía
          pública y espacios cerrados.
        </ModDotList>
        <ModText>
          La <strong>Agencia Estatal de Seguridad Aérea</strong> ha publicado
          una{" "}
          <InlineLink link="https://www.seguridadaerea.gob.es/media/4749654/gu%C3%ADa-exenci%C3%B3n-ftl-covid-19.pdf">
            Guía informativa
          </InlineLink>{" "}
          para solicitar la exención de requisitos de limitaciones de tiempos de
          vuelo y descansos, en relación con COVID-19.
        </ModText>
        <ModText>
          Para la solicitud de la exención será imprescindible contar con la
          debida justificación de que no es posible hacer frente a esas
          circunstancias o necesidades de forma adecuada cumpliendo los
          requisitos aplicables.
        </ModText>
        <ModText>
          En materia socioeconómica, se ha ampliado el horario de atención al
          público del teléfono gratuito habilitado para dar información sobre el
          Ingreso Mínimo Vital (900 20 22 22), ante el elevado número de
          llamadas que está recibiendo. Desde hoy, estará disponible desde las
          8.30 hasta las 20.30 horas.
        </ModText>

        <ModImage
          src="/images/svg/17_jun_telefonos.svg"
          alt="numeros de atencion covid-19"
        />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day96;
